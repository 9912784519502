import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Heading } from '@chakra-ui/layout';

function App() {
  return (
    <div className="App">
      <Heading className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h4>HRBK app playground</h4>
      </Heading>
      <header className="App-header">
      </header>
    </div>
  );
}

export default App;
